<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.photo_url"
          :variant="`light-${resolveUserRoleVariant(userData.role)}`"
          size="100px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ userData.name }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            accept=".jpg,.jpeg,.png,.webp,.gif"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span
            v-if="typeof userData.photo_url !== 'object'"
            class="d-none d-sm-inline"
          >
            Change Image
          </span>
          <span v-else class="d-none d-sm-inline">Add Image</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          v-if="typeof userData.photo_url !== 'object'"
          variant="outline-secondary"
          class="ml-1"
          @click="removeImage()"
        >
          <span class="d-none d-sm-inline">Remove Image</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
    </b-media>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >

      <!-- User Info: Input Fields -->
      <b-form
        autocomplete="off"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>

          <!-- Field: Full Name -->
          <b-col
            cols="12"
            md="4"
          >

            <validation-provider
              #default="validationContext"
              name="Name"
              rules="required|min:3|max:100"
              >
              <b-form-group
                label="Name"
                label-for="full-name"
              >
                <b-form-input
                  id="full-name"
                  v-model="userData.name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                  autocomplete="off"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Email -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="userData.email"
                  type="email"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                  autocomplete="off"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>


          <!-- Field: job title -->
          <b-col
              cols="12"
              md="4"
          >
            <validation-provider
                #default="validationContext"
                name="Job title"
                rules=""
            >
              <b-form-group
                  label="Job title"
                  label-for="job_title"
              >
                <b-form-input
                    id="job_title"
                    v-model="userData.job_title"
                    type="text"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: phone -->
          <b-col
              cols="12"
              md="4"
          >
            <validation-provider
                #default="validationContext"
                name="Phone number"
                rules=""
            >
              <b-form-group
                  label="Phone number"
                  label-for="phone"
              >
                <b-form-input
                    id="phone"
                    v-model="userData.phone"
                    type="text"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                    autocomplete="off"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: linkedin -->
          <b-col
              cols="12"
              md="4"
          >
            <validation-provider
                #default="validationContext"
                name="LinkedIn profile"
                rules="url"
            >
              <b-form-group
                  label="LinkedIn profile"
                  label-for="linked_in_url"
              >
                <b-form-input
                    id="linked_in_url"
                    v-model="userData.linked_in_url"
                    type="text"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                    autocomplete="off"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: bio -->
          <b-col
              cols="12"
              md="12"
          >
            <validation-provider
                #default="validationContext"
                name="Bio"
                rules=""
            >
              <b-form-group
                  label="Bio"
                  label-for="bio"
              >
                <b-form-textarea
                    id="bio"
                    v-model="userData.bio"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                    rows="4"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>


          <!-- Country -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Country"
              rules="required"
            >
              <b-form-group
                label="Country"
                label-for="country"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="userData.country"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="countries"
                  :state="getValidationState(validationContext)"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="country"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Status -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              v-if="!currentUserLoggedIn"
              #default="validationContext"
              name="Status"
              rules="required"
            >
              <b-form-group
                label="Status"
                label-for="user-status"
              >
                <v-select
                  v-model="userData.enabled"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  :state="getValidationState(validationContext)"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="user-status"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>


        </b-row>
        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          Save Changes
        </b-button>
        <b-button
          variant="outline-secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :to="{ name: 'myorg-view' }"
        >
          Cancel
        </b-button>
      </b-form>
    </validation-observer>

  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormTextarea
} from 'bootstrap-vue'
// import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import store from '@/store'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import countries from '@/countries-data.js'
import router from '@/router'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import useUsersList from '../../user/users-list/useUsersList'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,
    BForm,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    orgOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props) {
    const toast = useToast()
    const {
      resolveUserRoleVariant,
      roleOptions,
      statusOptions,
      showOnTeamPage,
    } = useUsersList()

    const currentUserLoggedIn = useJwt.getUserData().id == router.currentRoute.params.id

    // Add validation for a full name
    //const fullNameValidation = /^(([a-zA-Z\-\']+[ ])*)+[a-zA-Z\-\']*$/

    // Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.photo_url = base64
    })

    const removeImage = () => {
      // eslint-disable-next-line no-param-reassign
      props.userData.photo_url = null
    }

    const onSubmit = () => {
      store.dispatch('app/updateUser', { id: router.currentRoute.params.id, userData: props.userData })
        .then(() => {
          router.push({ name: 'myorg-view' })
        })
        .catch(error => {
          if(typeof error.response === 'undefined') {
            //log error
            console.error(error)
          } else {
            //alert user to error
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error updating user.',
                text: 'ERROR: ' + error.response.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            console.log(error.response.data.message)
          }
        })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      currentUserLoggedIn,
      resolveUserRoleVariant,
      // avatarText,
      roleOptions,
      statusOptions,
      showOnTeamPage,

      // form
      onSubmit,
      refFormObserver,
      getValidationState,

      // Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,

      removeImage,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
